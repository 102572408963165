
import { OtcInfo } from '../based-finance/types';

export const otcInfo: { [chainName: string]: OtcInfo } = {
    FTM: {
        chainID: 250,
        address: "0x8ff9eFB99D522fAC6a21363b7Ca54d25477637F6",
        tokensFrom: [
            {
                isTaxed: false,
                name: "SMELT",
                address: "0x141faa507855e56396eadbd25ec82656755cd61e",
                symbol: "SMELT",
                isRoot: false
            }
        ],
        tokensTo: [
            {
                isTaxed: false,
                name: "FTM",
                address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
                symbol: "FTM",
                isRoot: true
            }
        ],
    },
    POLYGON: {
        address: "0x8ff9eFB99D522fAC6a21363b7Ca54d25477637F6",
        chainID: 251,
        tokensFrom: [],
        tokensTo: [],
    }
  };