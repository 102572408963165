// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@spookyswap/sdk';
import { Configuration } from './based-finance/config';
import { BankInfo } from './based-finance';

const configurations: { [env: string]: Configuration } = {
  development: {
    chainId: ChainId.FTMTESTNET,
    networkName: 'Fantom Opera Testnet',
    ftmscanUrl: 'https://testnet.ftmscan.com',
    defaultProvider: 'https://rpc.testnet.fantom.network/',
    deployments: require('./based-finance/deployments/deployments.testing.json'),
    externalTokens: {
     
      WFTM: ['0xf1277d1ed8ad466beddf92ef448a132661956621', 18, 250],
      'USDC-FTM-LP': ['0xE7e3461C2C03c18301F66Abc9dA1F385f45047bA', 18, 250],
    },
    externalNftTokens: {
      'JOPA': ['0x9D12EDD4A590aee205cb205aA0906035d56a4FdE', 18]
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    acropolisLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
  production: {
    chainId: ChainId.MAINNET,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ftm.tools/',
    deployments: require('./based-finance/deployments/deployments.mainnet.json'),
    externalTokens: { 
      GodNFT: ['0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', 18, 250],
      GodFootPrints: ['0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', 18, 250], //OBOL TEST
      aBased: ['0x1C1CDF8928824dac36d84B3486D598B9799bA6c0', 18, 43114],
      pBased: ['0xd44779F0E18729cB6AFB7548cF10EF448a602668', 18, 137],
      Based: ['0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', 18, 250],
      GodNFTPoly: ['0xc978B4d4E9D2e8047E01A577c2b29d5A6Cb2eF5E', 18, 137],
      zkBased: ['0x8a78ec16D4B0d3230441cbB8b43DD18c0b8Dc01c', 18, 324], //INVALID
      bBased: ['0x74235c64f806341C14bF2be0887EEcd55daE955E', 18, 8453]

    },
    externalNftTokens: {
    },
    baseLaunchDate: new Date('2022-02-05 13:00:00Z'),
    bondLaunchesAt: new Date('2022-02-07T15:00:00Z'),
    acropolisLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding TOMB
        - 2 = LP asset staking rewarding TSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  
  //SMELT FRAMING POOLS 
  GodNftPool: {
    name: 'Prometheus NFT',
    poolId: 0,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'GodNFT',
    depositTokenAddress: '0xAf090a2F9fa2245e5a5F49584B84e0fC98C46479',
    sort: 1,
    isDepositNft: true,
    isEnabled: true,
    type: 0,
    chainID: 250,
    chainLogo: 'FTM',
    protocolSite: 'https://next-gen.basedfinance.io/farming',
    protocolLogo: 'basedV2',
    closedForStaking: false,
    recieptTokenName: 'GodFootPrints'
  },
  GodFootprintsPool: {
    name: 'GODs FootPrint',
    poolId: 1,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'GodFootPrints',
    sort: 1,
    isDepositNft: true,
    isEnabled: true,
    type: 1,
    chainID: 250,
    chainLogo: 'GODFootprint',
    protocolSite: 'https://next-gen.basedfinance.io/',
    protocolLogo: 'basedV2'
  },
  FlairDexPool: {
    protocolName: 'FlairDex AMM',
    name: 'aBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'aBased',
    sort: 3,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 43114,
    chainLogo: 'AVAX',
    protocolSite: 'https://www.flairdex.xyz/',
    protocolLogo: 'FLAIR',
    protocolLinkSite: 'https://app.flairdex.xyz/liquidity',
    twitterLink: 'https://twitter.com/FlairDefi',
    discordLink: 'https://discord.gg/aN3jjvk8KN'
  },
  ChamPool: {
    protocolName: 'Champion  Optimizer',
    name: 'aBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'aBased',
    sort: 5,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 43114,
    chainLogo: 'AVAX',
    protocolSite: 'https://app.championfinance.io',//https://championfinance.io/
    protocolLinkSite: 'https://app.championfinance.io/detail/FLAIR_FLDX_aBASED',
    protocolLogo: 'CHAM',
    twitterLink: 'https://twitter.com/Champion_io',
    discordLink: 'https://discord.gg/champion-finance'
  }
  ,
  GlacierPool: {
    protocolName: 'Glacier AMM',
    name: 'aBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'aBased',
    sort: 4,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 43114,
    chainLogo: 'AVAX',
    protocolSite: 'https://www.glacierfi.com/',
    protocolLogo: 'GLACIER',
    protocolLinkSite: 'https://glacier.exchange/liquidity',
    twitterLink: 'https://twitter.com/Glacier_Fi',
    discordLink: 'https://discord.gg/glacierdex'
  },
  SolisnekPool: {
    protocolName: 'SoliSnek AMM',
    name: 'aBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'aBased',
    sort: 5,
    closedForStaking: true,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 43114,
    chainLogo: 'AVAX',
    protocolSite: 'https://www.solisnek.finance/home/',
    protocolLogo: 'SOLISNEK',
    protocolLinkSite: 'https://solisnek.finance/liquidity',
    twitterLink: 'https://twitter.com/solisnekfi',
    discordLink: 'https://discord.gg/ERkhWdPNfD'
  },
  BasedAvaxPool: {
    protocolName: 'aBased',
    name: 'aBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'aBased',
    sort: 0,
    isDepositNft: true,
    isEnabled: true,
    type: 3,
    chainID: 43114,
    chainLogo: 'AVAX',
  },
  BasedPolyPool: {
    protocolName: 'pBased',
    name: 'pBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'pBased',
    sort: 0,
    isDepositNft: true,
    isEnabled: true,
    type: 3,
    chainID: 137,
    chainLogo: 'POLYGON',
  },
  BasedFtmPool: {
    protocolName: 'aBased',
    name: 'BASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'Based',
    sort: 0,
    isDepositNft: true,
    isEnabled: true,
    type: 3,
    chainID: 250,
    chainLogo: 'FTM',
  },
  BasedZkPool: {
    protocolName: 'zkBased',
    name: 'zkBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'zkBased',
    sort: 0,
    isDepositNft: true,
    isEnabled: true,
    type: 3,
    chainID: 324,
    chainLogo: 'zkSync',
  },
  BasedBasePool: {
    protocolName: 'Based',
    name: 'Based',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'bBased',
    sort: 0,
    isDepositNft: true,
    isEnabled: true,
    type: 3,
    chainID: 8453,
    chainLogo: 'BASE',
  },
  KaliburPool: {
    protocolName: 'eZKalibur',
    name: 'zkBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'zkBased',
    sort: 5,
    closedForStaking: true,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 324,
    chainLogo: 'zkSync',
    protocolSite: 'https://www.ezkalibur.com/',
    protocolLogo: 'kalibur',
    protocolLinkSite: 'https://www.ezkalibur.com/swap',
    twitterLink: 'https://twitter.com/zkaliburDEX',
    discordLink: 'https://discord.com/invite/ypqHnKE5KF'
  },
  VeSyncPool: {
    protocolName: 'veSync',
    name: 'zkBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'zkBased',
    sort: 5,
    closedForStaking: true,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 324,
    chainLogo: 'zkSync',
    protocolSite: 'https://vesync.finance',
    protocolLogo: 'veSync',
    protocolLinkSite: 'https://vesync.finance',
    twitterLink: 'https://twitter.com/veSyncL2',
    discordLink: 'https://discord.com/invite/yP2FCcXH8h'
  },
  DraculaFiSyncPool: {
    protocolName: 'DraculaFi',
    name: 'zkBASED',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'zkBased',
    sort: 6,
    closedForStaking: true,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 324,
    chainLogo: 'zkSync',
    protocolSite: 'https://draculafi.xyz',
    protocolLogo: 'dracula',
    protocolLinkSite: 'https://draculafi.xyz',
    twitterLink: 'https://twitter.com/dracula_fi',
    discordLink: 'https://discord.com/invite/draculafi'
  },
  BasePool: {
    protocolName: 'BasoFi',
    name: 'Base',
    poolId: 2,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'bBased',
    sort: 6,
    closedForStaking: true,
    isDepositNft: true,
    isEnabled: true,
    type: 2,
    chainID: 8453,
    chainLogo: 'BASE',
    protocolSite: 'https://www.baso.finance',
    protocolLogo: 'BASO',
    protocolLinkSite: 'https://www.baso.finance',
    twitterLink: 'https://twitter.com/Baso_Finance',
    discordLink: 'https://discord.com/invite/basofinance'
  },
  // BasedPool: {
  //   protocolName: 'BasedFinance',
  //   name: 'aBASED',
  //   poolId: 0,
  //   contract: 'NftFarmingRewardPool',
  //   depositTokenName: 'Based',
  //   sort: 5,
  //   closedForStaking: true,
  //   isDepositNft: true,
  //   isEnabled: true,
  //   type: 2,
  //   chainID: 250,
  //   chainLogo: 'FTM',
  //   protocolSite: 'https://basedfinance.io',
  //   protocolLogo: 'basedV2',
  //   protocolLinkSite: 'https://basedfinance.io/marketplace',
  //   twitterLink: 'https://twitter.com/solisnekfi',
  //   discordLink: 'https://discord.gg/ERkhWdPNfD'
  // },
  GodNftPolyPool: {
    name: 'Prometheus NFT Poly',
    poolId: 0,
    contract: 'NftFarmingRewardPool',
    depositTokenName: 'GodNFTPoly',
    depositTokenAddress: '0x214E93B34B4AAA9E8cb8dc3a193dc09142daE4A0',
    sort: 1,
    isDepositNft: true,
    isEnabled: true,
    type: 0,
    chainID: 137,
    chainLogo: 'POLYGON',
    protocolSite: '',
    protocolLogo: 'basedV2',
    closedForStaking: true,
    recieptTokenName: 'GodFootPrints',
  },
};

export default configurations['production'];
