import React, { createContext, useEffect, useState } from 'react';
import {BridgeClass} from "../../based-finance/classes/BridgeClass";
import useBasedFinance from "../../hooks/useBasedFinance";

export interface BridgeContext {
    bridge?: BridgeClass;
}

export const Context = createContext<BridgeContext>({ bridge: null });

export const BridgeProvider: React.FC = ({  children }) => {
    const [bridge, setBridge] = useState<BridgeClass>();
    const basedFinance = useBasedFinance();

    useEffect(() => {
        if (!bridge && basedFinance ) {
            const bridgeLocal = new BridgeClass(basedFinance);
            setBridge(bridgeLocal);
        }
    }, [basedFinance, bridge]);

    return <Context.Provider value={{ bridge }}>{children}</Context.Provider>;
};