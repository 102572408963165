import React, { createContext, useEffect, useState } from 'react';
import BasedFinance from '../../based-finance';
import config from '../../config';
import { useWeb3ProviderContext } from '../WalletProvider/WalletProvider';

export interface BasedFinanceContext {
  basedFinance?: BasedFinance;
}

export const Context = createContext<BasedFinanceContext>({ basedFinance: null });

export const BasedFinanceProvider: React.FC = ({ children }) => {
  const [basedFinance, setBasedFinance] = useState<BasedFinance>();
  const {account, chainParams, provider, library } = useWeb3ProviderContext();

  useEffect(() => {
    if (!basedFinance) {
      const based = new BasedFinance(config);
      setBasedFinance(based);
    } 
  }, [basedFinance]);

  useEffect(() => {
    if (basedFinance && chainParams) {
      basedFinance.unlockWallet(library, account, Number(chainParams.chainId))
    } 
  }, [basedFinance, account, chainParams, provider]);
  return <Context.Provider value={{ basedFinance }}>{children}</Context.Provider>;
};
