import { BasedFinance } from '../BasedFinance';
import { BigNumber, Contract, ethers } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';
import { getDisplayBalance, getFullDisplayBalance } from '../../utils/formatBalance';
import { SPOOKY_ROUTER_ADDR, TICKER } from '../../utils/constants';
import { Fetcher, Route, Token } from '@spookyswap/sdk';
import ERC20 from '../ERC20';
import { formatUnits, parseUnits } from 'ethers/lib/utils';

import  { bridgeInfo, nftBridgeInfo } from '../../configs/bridge_config';
import { DexSwapInfo, DexTokenInfo, BridgeInfo, OtcStats, BridgeEstimateInfo, BridgePairInfo } from '../types';
import { testClient } from '../apollo/client';
import { TOKEN_DATA } from '../apollo/queries';


export class BridgeClass {
  bridgeInfo: { [chainID: number]: BridgeInfo };
  basedFinance: BasedFinance;
  bridgeTokensInfo: { [address: string]: DexTokenInfo };
  bridgeNFTInfo: { [chainID: number]: BridgeInfo };
  bridgeNftTokensInfo: { [address: string]: DexTokenInfo };


  constructor(basedFinance: BasedFinance) {
    this.bridgeInfo = {};
    this.bridgeNFTInfo = {};
    this.basedFinance = basedFinance;
    this.bridgeTokensInfo = {};
    this.bridgeNftTokensInfo = {};

    for (const info of Object.values(bridgeInfo)) {
        this.bridgeInfo[info.chain.chainID] = info;
    } 

    for (const info of Object.values(nftBridgeInfo)) {
      this.bridgeNFTInfo[info.chain.chainID] = info;
  } 
  }

  async getBridgeInfo(chainID: number): Promise<BridgeInfo> {
    const bridgeInfoByChain = this.bridgeInfo[chainID];
    if( !bridgeInfoByChain )
        return undefined;

    if( this.basedFinance.isUnlocked ){
        for( let i = 0; i < bridgeInfoByChain.chainsTo.length; i++ ){
            let chainTo = bridgeInfoByChain.chainsTo[i];
            for(let k = 0; k < chainTo.tokensFrom.length; k++ ){
                let tokenFrom = chainTo.tokensFrom[k];
                if( !tokenFrom.token.contract ){
                  try{
                    tokenFrom.token.contract =  new ERC20(tokenFrom.token.address, this.basedFinance.provider, tokenFrom.token.symbol);
                    tokenFrom.token.contract.connect(this.basedFinance.signer);
                  }
                  catch(err){}
                }
                this.bridgeTokensInfo[tokenFrom.token.address] = tokenFrom.token;
            }
        }
    }
    return bridgeInfoByChain;
  }

  async getBridgeNftInfo(chainID: number): Promise<BridgeInfo> {
    const bridgeInfoByChain = this.bridgeNFTInfo[chainID];
    if( !bridgeInfoByChain )
        return undefined;

    if( this.basedFinance.isUnlocked ){
        for( let i = 0; i < bridgeInfoByChain.chainsTo.length; i++ ){
            let chainTo = bridgeInfoByChain.chainsTo[i];
            for(let k = 0; k < chainTo.tokensFrom.length; k++ ){
                let tokenFrom = chainTo.tokensFrom[k];
                if( !tokenFrom.feeToken.contract ){
                  try{
                    tokenFrom.feeToken.contract =  new ERC20(tokenFrom.feeToken.address, this.basedFinance.provider, tokenFrom.feeToken.symbol);
                    tokenFrom.feeToken.contract.connect(this.basedFinance.signer);
                  }
                  catch(err){}
                }
                if( !tokenFrom.token.contract ){
                  try{
                    tokenFrom.token.contract =  new ERC20(tokenFrom.token.address, this.basedFinance.provider, tokenFrom.token.symbol);
                    tokenFrom.token.contract.connect(this.basedFinance.signer);
                  }
                  catch(err){}
                }
                this.bridgeNftTokensInfo[tokenFrom.token.address] = tokenFrom.token;
            }
        }
    }
    return bridgeInfoByChain;
  }

  async getTokenBalance(token: DexTokenInfo): Promise<number>{
    try{
        if( !token.contract && this.basedFinance.isUnlocked ){
            token.contract =  new ERC20(token.address, this.basedFinance.provider, token.symbol);
            token.contract.connect(this.basedFinance.signer);
        } else if( !this.basedFinance.isUnlocked ){
          return 0;
        }

        const balance = await token.contract.balanceOf(this.basedFinance.myAccount);
        token.balance = balance;
        return Number(getDisplayBalance(balance, token.contract.decimal,8));
    }
    catch(error){}
    return 0;
  }

  async getBridgeEstimateInfo(pairInfo: BridgePairInfo, feeToken: DexTokenInfo, tokenFrom: DexTokenInfo, tokenTo: DexTokenInfo, amount: number, contractName: string): Promise<BridgeEstimateInfo> {

    const contract = this.basedFinance.contracts[contractName];
  
    if( contract && amount > 0 ){

      let res = await contract.estimateTransferFees(pairInfo.chain.chainID, this.basedFinance.myAccount, tokenFrom.address, ethers.utils.parseUnits(amount.toString(),
      tokenFrom.contract.decimal));
      if(res.length > 1 ){
        return {feeValue: Number(res[1])/ 1e18, feeFtm: res[0], chainId: pairInfo.chain.chainID,
         account: this.basedFinance.myAccount, tokenAddress: tokenFrom.address,
          amount: amount, contractName: contractName, tokenDecimal: tokenFrom.contract.decimal}
      }
    }
    else{
    }
    return {feeValue: 0, feeFtm: BigNumber.from(0), chainId: 0, account: "", tokenAddress: "", amount: 0, contractName:"", tokenDecimal: 0};
  }

  async getBridgeNftEstimateInfo(pairInfo: BridgePairInfo, feeToken: DexTokenInfo,
     tokenFrom: DexTokenInfo, tokenID: number, contractName: string, rcvAddress: string): Promise<BridgeEstimateInfo> {

    const contract = this.basedFinance.contracts[contractName];

    if( contract && tokenID > 0 ){
      let res = await contract.estimateTransferFees(pairInfo.chain.chainBridgeID, rcvAddress, tokenFrom.address, tokenID.toString());

      if(res.length > 1 ){
        return {feeValue: Number(res[1])/ 1e18, feeFtm: res[0], chainId: pairInfo.chain.chainBridgeID,
         account: rcvAddress, tokenAddress: tokenFrom.address,
          amount: tokenID, contractName: contractName, tokenDecimal: tokenFrom.contract.decimal}
      }
    }
    else{
    }
    return {feeValue: 0, feeFtm: BigNumber.from(0), chainId: 0, account: "", tokenAddress: "", amount: 0, contractName:"", tokenDecimal: 0};
  }


  async callBridge(info: BridgeEstimateInfo): Promise<TransactionResponse> {
    const contract = this.basedFinance.contracts[info.contractName];
    let tx;

    if( contract && info && info.amount ){
      if( info.amount > 0 && this.basedFinance.isUnlocked && info.account === this.basedFinance.myAccount){
        const options = {value: info.feeFtm}
        return await contract.bridge(info.chainId, info.account, info.tokenAddress,ethers.utils.parseUnits(info.amount.toString(),
        info.tokenDecimal) ,"0x0000000000000000000000000000000000000000"
        ,'0x', options)
      }
    }
    return tx;
  }

  async callBridgeNft(info: BridgeEstimateInfo): Promise<TransactionResponse> {
    const contract = this.basedFinance.contracts[info.contractName];
    let tx;

    if( contract && info && info.amount ){
      if( info.amount > 0 && this.basedFinance.isUnlocked && info.account !==  ""){
        const options = {value: info.feeFtm}

        return await contract.bridge(info.chainId, info.account, info.tokenAddress,info.amount ,"0x0000000000000000000000000000000000000000"
        ,'0x', options)
      }
    }
    return tx;
  }


}

