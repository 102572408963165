import { Fetcher, Fetcher as FetcherSpirit, Route, Token, Token as TokenSpirit } from '@spookyswap/sdk';
import { Configuration } from './config';
import {
  ContractName,
  ShareTokenStat,
  
  Bank,
  PoolStats,
 
} from './types';
import { BigNumber, Contract, ethers, EventFilter, Event } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';
import ERC20 from './ERC20';
import { getFullDisplayBalance, getDisplayBalance } from '../utils/formatBalance';
import { getDefaultProvider } from '../utils/provider';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import config, { bankDefinitions } from '../config';

export class BasedFinance {
  myAccount: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.providers.JsonRpcSigner;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: any };
  acropolisVersionOfUser?: string;
  parthenonVersionOfUser?: string;
  acropolisFundEvents: Array<Event> = [];
  lastEpoch: number = 0;

  FTM: ERC20;
  WFTM: ERC20;
  USDC: ERC20;
  OBOL: ERC20;
  SMELT: ERC20;
  
  OBOLFTM_LP: Contract;
  SMELTFTM_LP: Contract;

  STATER: ERC20;
  SMELTER: ERC20;
  nftRankData: any;

  constructor(cfg: Configuration) {
    const { deployments, externalTokens } = cfg;
    this.config = cfg;
    this.nftRankData = require('../assets/rank.json');
  }

    /**
   * Deposits token to given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
     async stakeNFT(poolName: ContractName, poolId: Number, amount: any[]): Promise<TransactionResponse> {
      const pool = this.contracts[poolName];
      return await pool.StakeNft(poolId, amount);
    }
  
      /**
     * Withdraws token from given pool.
     * @param poolName A name of pool contract.
     * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
     * @returns {string} Transaction hash
     */
       async unstakeNFT(poolName: ContractName, poolId: Number, amount: any[]): Promise<TransactionResponse> {
        const pool = this.contracts[poolName];
        return await pool.UnstakeNft(poolId, amount);
      }

    lockWallet() {
      this.myAccount = undefined;
      this.provider = undefined;
      this.signer = undefined;
      this.contracts = {};
      this.externalTokens = {};
      console.log(`🔓 Wallet is locked.`);
    }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string, chainId: number) {
    if( !account || !provider )
      return;
    

    const newProvider = new ethers.providers.Web3Provider(provider, chainId);
    this.signer = provider.getSigner();

    const { deployments, externalTokens } = this.config;
        // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      if( deployment.chain === chainId )
        this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal, chain]] of Object.entries(externalTokens)) {
      if( chain === chainId )
        this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
    }
    
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }

    const tokens = [ ...Object.values(this.externalTokens)];
    for (const token of tokens) {
      token.connect(this.signer);
    }

    this.myAccount = account;
    this.provider = newProvider;
    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }


  /**
   * Method to calculate the tokenPrice of the deposited asset in a pool/bank
   * If the deposited token is an LP it will find the price of its pieces
   * @param tokenName
   * @param token
   * @returns
   */
  async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
    let tokenPrice;
   
      if (tokenName === 'GodNft'){
      tokenPrice = '500';}
 
    return tokenPrice;
  }
 
  //---------------- ToDo Replace this with real contract call ----------------
  mockTransactionResponse = {
    hash: '0x0',
    confirmations: 1,
    from: '0x0',
    wait: () => {},
  };
 

  async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    if(!pool)
      return;
    try {
      let userInfo = await pool.userInfo(poolId, account);
      return await userInfo.amount;
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }



  async getTokenPriceInFtmShort(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;
    const { WFTM } = this.config.externalTokens;

    const wftm = new Token(chainId, WFTM[0], WFTM[1]);
    const token = new Token(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const ftmToToken = await Fetcher.fetchPairData(wftm, token, this.provider);
      const priceInBUSD = new Route([ftmToToken], token);

      return priceInBUSD.midPrice.toFixed(4);
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  async getTokenPriceInFtm(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;

    const { WFTM } = this.externalTokens;

    const ftm = new TokenSpirit(chainId, WFTM.address, WFTM.decimal);
    const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const ftmToToken = await FetcherSpirit.fetchPairData(ftm, token, this.provider);
      const liquidityToken = ftmToToken.liquidityToken;

      let ftmBalanceInLP = await WFTM.balanceOf(liquidityToken.address);
      let ftmAmount = Number(getFullDisplayBalance(ftmBalanceInLP, WFTM.decimal));
      let tokenBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
      let tokenAmount = Number(getFullDisplayBalance(tokenBalanceInLP, tokenContract.decimal));
      // const priceOfOneTombInFtm = await this.getOneTOMBPriceInFTM();
      let priceOftoken = (ftmAmount / tokenAmount);
      return Number(priceOftoken).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  async getFTMPriceInDollars(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WFTM, USDC } = this.externalTokens;
    try {
      const usdc_ftm_lp_pair = this.externalTokens['FTM-USDC-LP'];
      let ftm_amount_BN = await WFTM.balanceOf(usdc_ftm_lp_pair.address);
      let ftm_amount = Number(getFullDisplayBalance(ftm_amount_BN, WFTM.decimal));
      let usdc_amount_BN = await USDC.balanceOf(usdc_ftm_lp_pair.address);
      let usdc_amount = Number(getFullDisplayBalance(usdc_amount_BN, USDC.decimal));
      return (usdc_amount / ftm_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of WFTM: ${err}`);
    }
  }


  //TODO add proper icons
  async watchAssetInMetamask(assetName: string,  contract?: ERC20): Promise<boolean> {
    const { ethereum } = window as any;
    if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
      let asset;
      let assetUrl;
       if (assetName === 'SMELT') {
        asset = this.SMELT;
        assetUrl = window.location.origin + '/smelt.svg';
      }
      else if (assetName === 'OBOL') {
        asset = this.OBOL;
        assetUrl = window.location.origin + '/obol.svg';
      }
      else{
        if( contract ){
          asset = contract;
          assetUrl = window.location.origin + '/decorativeToken.png';
        }
        else
          return;
      }
      
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: asset.address,
            symbol: asset.symbol,
            decimals: 18,
            image: assetUrl,
          },
        },
      });
    }
    return true;
  }

  async getExternalTokenBalanceByName(tokenName: string, account: string): Promise<BigNumber> {
    let contract = this.externalTokens[tokenName];
    if (!contract && !account) return BigNumber.from(0);
    return await contract.balanceOf(account);
  }
  
}
