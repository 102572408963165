import { useEffect, useState } from "react";
import { createContext } from "@dwarvesf/react-utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import React from "react";
import useBasedFinance from '../../hooks/useBasedFinance';


export const chains = [
  {
    chainId: `0x${(250).toString(16)}`,
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools/","https://rpc.ftm.tools"],
    blockExplorerUrls: ["https://ftmscan.com/"],
  },
  {
    chainId: `0x${(56).toString(16)}`,
    chainName: "Smart Chain",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
  {
    chainId: `0x${(43114).toString(16)}`,
    chainName: "Avalanche Network",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
  },
  {
    chainId: `0x${(137).toString(16)}`,
    chainName: "Polygon",
    nativeCurrency: {
      name: "Polygon",
      symbol: "Polygon",
      decimals: 137,
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  {
    chainId: `0x${(324).toString(16)}`,
    chainName: "zkSync",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.era.zksync.io"],
    blockExplorerUrls: ["https://explorer.zksync.io/"],
  },
  {
    chainId: `0x${(8453).toString(16)}`,
    chainName: "Base",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ["	https://basescan.org"],
  },
  {
    chainId: `0x${(777).toString(16)}`,
    chainName: "Not Supported",
    nativeCurrency: {
      name: "Not Supported",
      symbol: "Not Supported",
      decimals: 18,
    },
    rpcUrls: [""],
    blockExplorerUrls: [""],
  }
];

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "", // required
      rpc: {
        250: "https://rpc.ftm.tools",
        56: "https://bsc-dataseed.binance.org/"
      }
    },
  },
};


interface ChainParams {
  chainId: string,
  chainName: string,
  nativeCurrency: {
    name: string,
    symbol: string,
    decimals: number,
  },
  rpcUrls: string[],
  blockExplorerUrls: string[],
}

interface State {
  web3Modal?: Web3Modal;
  provider?: any;
  library?: ethers.providers.Web3Provider;
  signer?: ethers.providers.JsonRpcSigner;
  accounts?: string[];
  account?: string;
  network?: ethers.providers.Network;
  connect: () => void;
  disconnect: () => void;
  switchChain: (chainId: number) => void;
  chainParams?: ChainParams;
  chains: {name: string, id: number}[];
}

const [Context, useWeb3ProviderContext] = createContext<State>({
  name: "Web3ProviderContext",
});

const Web3ProviderContext: React.FC = ({ children }) => {
  const [web3Modal, setWeb3Modal] = useState<Web3Modal>();
  const [provider, setProvider] = useState<any>();
  const [library, setLibrary] = useState<ethers.providers.Web3Provider>();
  const [signer, setSigner] = useState<ethers.providers.JsonRpcSigner>();
  const [accounts, setAccounts] = useState<string[]>([]);
  const [network, setNetwork] = useState<ethers.providers.Network>();
  const [chainParams, setChainParams] = useState<ChainParams>();

  // const basedFinance = useBasedFinance();
  


  const connect = async () => {

    try{
      let provider = await web3Modal?.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const signer = library.getSigner();
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();

      setProvider(provider);
      setLibrary(library);
      setSigner(signer);
      setAccounts(accounts);
      setNetwork(network);

      

      let chainData = chains.find(
        (chain) => chain.chainId === `0x${network.chainId.toString(16)}`
      )
      
      if( chainData ){
        // connectToNetworkAny(provider, network.chainId, network.name, chainData.rpcUrls, chainData.blockExplorerUrls,
        //    chainData.nativeCurrency.symbol, chainData.nativeCurrency.decimals)
      }
      else{
        chainData = chains.find(
          (chain) => chain.chainId === `0x${(777).toString(16)}`)
      }
      setChainParams(chainData)
      
    }
    catch(error){
      console.log("Network = ", error)

    }
  };
  
  async function disconnect() {
    // try {
    //   basedFinance.unlockWallet(library,accounts[0], network.chainId);
    // } catch (ex) {
    //   console.log(ex)
    // }
  }
  const connectToNetworkAny = async (provider: any, chainId: number, networkName: string,
    rpcUrl: string[], networkURL: string[], symbol: string, decimals: number) => {
   await provider.request({
     method: 'wallet_addEthereumChain',
     params: [
       {
         chainId: `0x${chainId.toString(16)}`,
         chainName: networkName,
         nativeCurrency: {
           name:  networkName,
           symbol: symbol,
           decimals: decimals,
         },
         rpcUrls: rpcUrl,
         blockExplorerUrls: networkURL,
       },
     ],
   });
 };

  const detailsOn = async () =>  {
    try
    {
      const { ethereum } = window;
      let library = new ethers.providers.Web3Provider(ethereum);
      const signer = library.getSigner();
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();

      setProvider(ethereum);
      setLibrary(library);
      setSigner(signer);
      setAccounts(accounts);
      setNetwork(network);      
      let chainData = chains.find(
        (chain) => chain.chainId === `0x${network.chainId.toString(16)}`
      )

      if( chainData ){
        // connectToNetworkAny(ethereum, network.chainId, chainData.chainName, chainData.rpcUrls, chainData.blockExplorerUrls,
        //    chainData.nativeCurrency.symbol, chainData.nativeCurrency.decimals)
      }
      else{
        chainData = chains.find(
          (chain) => chain.chainId === `0x${(777).toString(16)}`)
      }
      setChainParams(chainData)

      return chainData;
    }
    catch(error)
    {
      console.log(error)
    }
  }


  const checkIfWalletIsConnected = async () =>{
    try{
      const { ethereum } = window;
      if(!ethereum){
        console.log("No Etherum")
      } else{
        console.log("Details on")
        detailsOn();
      }
      const accounts = await ethereum.request({method: 'eth_accounts'});
      if(accounts !== 0){
         detailsOn();
      } else{
        console.log("Could not find an authorized account");
      }
    } catch(error){
        console.log(error);
    }
  }



  useEffect(() => {
    try{
      setWeb3Modal(
        new Web3Modal({
          // network: "mainnet", // optional
          // cacheProvider: true, // optional
          providerOptions, // required
          theme: "dark"
        })
      );
    }
    catch(error){
      console.log(error)
    }
    checkIfWalletIsConnected()
  }, []);
  

  useEffect(() => {
    // checkIfWalletIsConnected();
    if (provider) {
      // Subscribe to accounts change
      provider.on("accountsChanged", (accounts: string[]) => {
        console.log("account changed " + accounts)
        setAccounts(accounts);
      });

      // Subscribe to chainId change
      provider.on("chainChanged", async () => {
        window.location.reload();
      });
    }
  }, [provider]);

  const switchChain = async (chainId: number) => {
    try {
      window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              chains.find(
                (chain) => chain.chainId === `0x${chainId.toString(1)}`
              ),
            ],
          });
        } catch (addError) {
          throw addError;
        }
      }
    }
  };

  return (
    <Context
      value={{
        web3Modal,
        provider,
        library,
        signer,
        accounts,
        network,
        account: accounts[0],
        connect,
        disconnect,
        switchChain,
        chainParams,
        chains: [{ name: 'FTM_CHAIN_SIMPLE', id: 250}, {name: 'AVAX', id: 43114}, {name: 'POLYGON', id: 137}, {name: 'zkSync', id: 324}, {name: 'BASE', id: 8453}]
      }}
    >
      {children}
    </Context>
  );
};

export { Web3ProviderContext, useWeb3ProviderContext };
