import { BasedFinance } from './BasedFinance';
import { BigNumber, Contract, ethers } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';
import { getDisplayBalance, getFullDisplayBalance } from '../utils/formatBalance';
import { SPOOKY_ROUTER_ADDR, TICKER } from '../utils/constants';
import { Fetcher, Route, Token } from '@spookyswap/sdk';
import ERC20 from './ERC20';
import { formatUnits, parseUnits } from 'ethers/lib/utils';

import  { otcInfo } from '../configs/otc_config';
import { DexSwapInfo, DexTokenInfo, OtcInfo, OtcStats } from './types';
import { testClient } from './apollo/client';
import { TOKEN_DATA } from './apollo/queries';


export class Otc {
  otcSwapInfo: { [chainID: number]: OtcInfo };
  basedFinance: BasedFinance;
  otcTokensInfo: { [address: string]: DexTokenInfo };


  constructor(basedFinance: BasedFinance) {
    this.otcSwapInfo = {};
    this.otcTokensInfo = {};

    this.basedFinance = basedFinance;
    for (const info of Object.values(otcInfo)) {
        console.log(info)
        this.otcSwapInfo[info.chainID] = info;
    } 
  }

  async getOtcInfo(chainID: number): Promise<OtcInfo> {
    const otcInfoByChain = this.otcSwapInfo[chainID];
    if( !otcInfoByChain )
        return{chainID: 0, tokensFrom: [], tokensTo: [], address: ""};

    //CHECK IF TOKEN DOESNT EXISTS
    for( let i = 0; i < otcInfoByChain.tokensFrom.length; i++ ){
        let tokenInfo = otcInfoByChain.tokensFrom[i];

        if( !tokenInfo.contract ){
            tokenInfo.contract =  new ERC20(tokenInfo.address, this.basedFinance.provider, tokenInfo.symbol);
            tokenInfo.contract.connect(this.basedFinance.signer);
        }
        tokenInfo.contract.connect(this.basedFinance.signer);
        this.otcTokensInfo[tokenInfo.address] = tokenInfo;

    }

    for( let i = 0; i < otcInfoByChain.tokensTo.length; i++ ){
        let tokenInfo = otcInfoByChain.tokensTo[i];
        if( !tokenInfo.contract ){
            tokenInfo.contract =  new ERC20(tokenInfo.address, this.basedFinance.provider, tokenInfo.symbol);
            tokenInfo.contract.connect(this.basedFinance.signer);
            this.otcTokensInfo[tokenInfo.address] = tokenInfo;
        }
    }

    return otcInfoByChain;
  }

  async getTokenBalance(token: string): Promise<number> {
    try {
        let tokenContract = this.otcTokensInfo[token].contract;
        if (tokenContract && this.basedFinance.myAccount) {
            if (token === "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83") {
                return 0;
            }
            const balance = await tokenContract.balanceOf(this.basedFinance.myAccount);
            return Number(getDisplayBalance(balance, tokenContract.decimal));
        }
    } catch (err) {
        console.error(`Failed to fetch token ${token} balance: ${err}`);
    }
    return 2;
}
async getSwapInfo(token0: DexTokenInfo, token1: DexTokenInfo,
    amount: number, slippage: number, isMainTokenChanged: boolean, chainID: number): Promise<DexSwapInfo> {
     let swapInfo: DexSwapInfo = {token0: token0, token1: token1, sellTax: 0, buyTax: 0, token0Price :0,
        token1Price : 0, minRecieved : 0, priceImpact: 0, fee: 0, discount : 0, swapAmount: 0, amountInput: amount, isMainTokenChanged: isMainTokenChanged};

     if(amount == 0)
         return;

    const otcInfoByChain = this.otcSwapInfo[chainID];

    if( otcInfoByChain ){
        //GET CONTRACT ADDRESS/NAME AND LETS DO IT
    }    

    let tokePrices = await this.getTokenPrice("");

    let token0PriceUSD = 0;
    let token1PriceUSD = 0;

    for(let i = 0; i < tokePrices.length; i++ ){
        const tokenInfo = tokePrices[i];
        console.log(tokenInfo)
        //@ts-ignore
        if(tokenInfo.tokenAddress === token0.address.toLocaleLowerCase() ){
            //@ts-ignore
            token0PriceUSD = tokenInfo.priceInUSD;
        }
        //@ts-ignore
        if(tokenInfo.tokenAddress === token1.address.toLocaleLowerCase() ){
            //@ts-ignore
            token1PriceUSD = tokenInfo.priceInUSD;
        }
    }

    console.log(token0PriceUSD)
    console.log(token1PriceUSD)

    if( isMainTokenChanged ){
        swapInfo.token0Price = 1
        swapInfo.token1Price = token0PriceUSD/token1PriceUSD
        swapInfo.swapAmount = amount * swapInfo.token1Price;
    }
    else {
        swapInfo.token0Price = token0PriceUSD/token1PriceUSD;
        swapInfo.token1Price = 1;
        swapInfo.swapAmount = amount * (token1PriceUSD/token0PriceUSD);
    }
 
    return swapInfo;
 }

 async getTokenPrice(tokenAddress: string): Promise<[]>{
    let current = await testClient.query({
        query: TOKEN_DATA,
        variables: {
        },
        fetchPolicy: 'cache-first',
      })
    console.log(current.data.tokensMany)
    return current.data.tokensMany;
 }

 async getOtcStats(chainID: number, tokenInfo: DexTokenInfo): Promise<OtcStats> {
    let otcStats : OtcStats = {tokensAvailable: 123234, tokensSwapped: 123}

    const otcInfoByChain = this.otcSwapInfo[chainID];


    if( otcInfoByChain ){
        //GET CONTRACT ADDRESS/NAME AND LETS DO IT
    }
    return otcStats;
 }
}

