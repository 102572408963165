import React, { createContext, useEffect, useState } from 'react';
import {Otc} from "../../based-finance/Otc";
import useBasedFinance from "../../hooks/useBasedFinance";

export interface OtcContext {
    otc?: Otc;
}

export const Context = createContext<OtcContext>({ otc: null });

export const OtcProvider: React.FC = ({  children }) => {
    const [otc, setOtc] = useState<Otc>();
    const basedFinance = useBasedFinance();

    useEffect(() => {
        if (!otc && basedFinance ) {
            const obj = new Otc(basedFinance);
            setOtc(obj);
        }
    }, [basedFinance, otc]);

    return <Context.Provider value={{ otc }}>{children}</Context.Provider>;
};