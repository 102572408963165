import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWallet } from 'use-wallet';
import useDebounce from '../../hooks/useDebounce';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import { updateBlockNumber } from './actions';
import { getDefaultProvider } from '../../utils/provider';
import { useWeb3ProviderContext } from '../../contexts/WalletProvider/WalletProvider';

export default function Updater(): null {
  // const { ethereum, chainId } = useWallet();
  const { account, chainParams, provider, library } = useWeb3ProviderContext();

  const dispatch = useDispatch();

  const windowVisible = useIsWindowVisible();

  const [state, setState] = useState<{
    chainId: number | undefined;
    blockNumber: number | null;
  }>({
    chainId: Number(chainParams?.chainId),
    blockNumber: null,
  });

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState((state) => {
        if ( Number(chainParams?.chainId) === state.chainId) {
          if (typeof state.blockNumber !== 'number') return { chainId:  Number(chainParams?.chainId), blockNumber };
          return { chainId:  Number(chainParams?.chainId), blockNumber: Math.max(blockNumber, state.blockNumber) };
        }
        return state;
      });
    },
    [chainParams?.chainId, chainParams, setState],
  );

  // attach/detach listeners
  // @ts-ignore
  useEffect(() => {
    if (!chainParams || !chainParams?.chainId || !windowVisible) return undefined;
    setState({ chainId:  Number(chainParams?.chainId), blockNumber: null });
    // const provider = getDefaultProvider();
    library
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch((error) => console.error(`Failed to get block number for chainId: ${ Number(chainParams?.chainId)}`, error));

      library.on('block', blockNumberCallback);
    return () => library.removeListener('block', blockNumberCallback);
  }, [dispatch,  Number(chainParams?.chainId), chainParams, provider, blockNumberCallback, windowVisible]);

  const debouncedState = useDebounce(state, 100);

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return;
    dispatch(
      updateBlockNumber({
        chainId: debouncedState.chainId,
        blockNumber: debouncedState.blockNumber,
      }),
    );
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId]);

  return null;
}
