
import { BridgeInfo } from '../based-finance/types';

// color: string;
// chainID: number;
// chainName: string;
// chainLogo: string;
// supportedTokens: DexTokenInfo[];

export const bridgeInfo: { [chainName: string]: BridgeInfo } = {
    FTM: {
        contractName: "BridgeFtm",
        contractAddress: "0x3dd5EC9eDA87edbD74a8149ACb76EF01b1F3A243",
        chain:{
            color: "blue",
            chainID: 250,
            chainName: "FTM",
            chainLogo: "FTM_CHAIN",
        },
        chainsTo: [
            {
                chain: {
                    color: "red",
                    chainID: 106,
                    chainName: "AVAX",
                    chainLogo: "AVAX",
                },
                tokensFrom: [
                    {
                        feeToken: {
                            isTaxed: false,
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        },
                        token: {
                            isTaxed: false,
                            name: "BASED",
                            address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            symbol: "BASED",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                                buyText: "Buy BASED",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough BASED",
                                buyHelperText2: ""
                            }
                        },
                        tokensTo: [
                            {
                                isTaxed: false,
                                name: "aBASED",
                                address: "0x1C1CDF8928824dac36d84B3486D598B9799bA6c0",
                                symbol: "BASED",
                                isRoot: false,
                                decorativeName: "aBased"
                            }
                        ]
                    }
                ] 
            },
            {
                chain: {
                    color: "blue",
                    chainID: 109,
                    chainName: "POLYGON",
                    chainLogo: "POLYGON",
                },
                tokensFrom: [
                    {
                        feeToken: {
                            isTaxed: false,
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        },
                        token: {
                            isTaxed: false,
                            name: "BASED",
                            address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            symbol: "BASED",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                                buyText: "Buy BASED",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough BASED",
                                buyHelperText2: ""
                            }
                        },
                        tokensTo: [
                            {
                                isTaxed: false,
                                name: "pBASED",
                                address: "0xd44779F0E18729cB6AFB7548cF10EF448a602668",
                                symbol: "BASED",
                                isRoot: false,
                                decorativeName: "pBased"
                            }
                        ]
                    }
                ] 
            },
            {
                chain: {
                    color: "blue",
                    chainID: 165,
                    chainName: "zkSync",
                    chainLogo: "zkSync",
                },
                tokensFrom: [
                    {
                        feeToken: {
                            isTaxed: false,
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        },
                        token: {
                            isTaxed: false,
                            name: "BASED",
                            address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            symbol: "BASED",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                                buyText: "Buy BASED",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough BASED",
                                buyHelperText2: ""
                            }
                        },
                        tokensTo: [
                            {
                                isTaxed: false,
                                name: "zkBASED",
                                address: "0x8a78ec16D4B0d3230441cbB8b43DD18c0b8Dc01c",
                                symbol: "zkBASED",
                                isRoot: false,
                                decorativeName: "zkBased"
                            }
                        ]
                    }
                ] 
            },
            {
                chain: {
                    color: "brown",
                    chainID: 184,
                    chainName: "BASE",
                    chainLogo: "BASE",
                },
                tokensFrom: [
                    {
                        feeToken: {
                            isTaxed: false,
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        },
                        token: {
                            isTaxed: false,
                            name: "BASED",
                            address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            symbol: "BASED",
                            isRoot: false,
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                                buyText: "Buy BASED",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough BASED",
                                buyHelperText2: ""
                            }
                        },
                        tokensTo: [
                            {
                                isTaxed: false,
                                name: "bBASED",
                                address: "0x74235c64f806341C14bF2be0887EEcd55daE955E",
                                symbol: "BASED",
                                isRoot: false,
                                decorativeName: "BASED"
                            }
                        ]
                    }
                ] 
            }
        ]
    }
  };

  export const nftBridgeInfo: { [chainName: string]: BridgeInfo } = {
    FTM: {
        contractName: "BridgeNftFtm",
        contractAddress: "0xE0a90dd7813b580b0Dd24E6C9BE84993dE9c535f",
        chain:{
            color: "blue",
            chainID: 250,
            chainName: "FTM",
            chainLogo: "FTM_CHAIN",
        },
        chainsTo: [
            {
                chain: {
                    color: "blue",
                    chainID: 137,
                    chainBridgeID: 109,
                    chainName: "Polygon",
                    chainLogo: "Polygon",
                },
                tokensFrom: [
                    {
                        feeToken: {
                            isTaxed: false,
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            isRoot: false,
                            disabled: false
                        },
                        token: {
                            isTaxed: false,
                            name: "GodNFT",
                            address: "0xc5777AD2250D7b12a3f2383afB4464b9E49bE297", //test nft
                            symbol: "GodNFT",
                            isRoot: false
                        },
                        tokensTo: [
                            {
                                isTaxed: false,
                                name: "GodNftPoly",
                                address: "0xc978B4d4E9D2e8047E01A577c2b29d5A6Cb2eF5E",
                                symbol: "GodNFT",
                                isRoot: false
                            }
                        ]
                    }
                ] 
            }
        ]
    },
    POLYGON: {
        contractName: "BridgeNftPoly",
        contractAddress: "0x9BC6DA9Be2dDC0B0b04b6D89Dc57c49FeDCac522",
        chain:{
            color: "blue",
            chainID: 137,
            chainName: "POLYGON",
            chainLogo: "POLYGON_CHAIN",
        },
        chainsTo: [
            {
                chain: {
                    color: "blue",
                    chainID: 250,
                    chainBridgeID: 112,
                    chainName: "FTM",
                    chainLogo: "FTM_CHAIN",
                },
                tokensFrom: [
                    {
                        feeToken: {
                            isTaxed: false,
                            name: "pBased",
                            address: "0xd44779F0E18729cB6AFB7548cF10EF448a602668",
                            symbol: "pBASED",
                            isRoot: false,
                            disabled: false,
                            buyHelp:{
                                buyLink: "https://quickswap.exchange/#/swap?currency0=ETH&currency1=0xd44779F0E18729cB6AFB7548cF10EF448a602668&swapIndex=0",
                                buyText: "Buy pBASED on POLYGON",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough pBASED.",
                                buyHelperText2: "Please use one of the following options:"
                            }
                        },
                        token: {
                            isTaxed: false,
                            name: "GodNFTPoly",
                            address: "0xc978B4d4E9D2e8047E01A577c2b29d5A6Cb2eF5E",
                            symbol: "GodNFTPoly",
                            isRoot: false
                        },
                        tokensTo: [
                            {
                                isTaxed: false,
                                name: "GodNFT",
                                address: "0xc5777AD2250D7b12a3f2383afB4464b9E49bE297",
                                symbol: "GodNFT",
                                isRoot: false
                            }
                        ]
                    }
                ] 
            }
        ]
    }
  };