import React, {Suspense, lazy, useEffect} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ThemeProvider as TP} from '@material-ui/core/styles';
import {ThemeProvider as TP1} from 'styled-components';
import {UseWalletProvider} from 'use-wallet';
import usePromptNetwork from './hooks/useNetworkPrompt';
import BanksProvider from './contexts/Banks';
import { ApolloProvider } from 'react-apollo'
import { testClient } from './based-finance/apollo/client'

import BasedFinanceProvider from './contexts/BasedFinanceProvider';
import ModalsProvider from './contexts/Modals';
import store from './state';
import theme from './theme';
import newTheme from './newTheme';
import config from './config';
import Updaters from './state/Updaters';
import Loader from './components/Loader';
import Popups from './components/Popups';
import {RefreshContextProvider} from './contexts/RefreshContext';
import useBasedFinance from "./hooks/useBasedFinance";
import { useWeb3ProviderContext, Web3ProviderContext }  from './contexts/WalletProvider/WalletProvider';
import useChainId from './hooks/useChainId';
import { InferProps, Requireable, ReactNodeLike } from 'prop-types';
import { Connector, ConnectorConfig } from 'use-wallet/dist/cjs/types';
import OtcProvider from './contexts/OtcProvider';
import BridgeProvider from './contexts/BridgeProvider';



const DashboardPage = lazy(() => import('./views/DashboardPage'));
const OtcPage = lazy(() => import('./views/OtcPage'));
const BridgePage = lazy(() => import('./views/BridgePage'));

const NoMatch = () => (
    <h3 style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        URL Not Found. <a href="/">Go back home.</a>
    </h3>
);




const App: React.FC = () => {

    // Clear localStorage for mobile users
    if (typeof localStorage.version_app === 'undefined' || localStorage.version_app !== '1.1') {
        localStorage.clear();
        localStorage.setItem('connectorId', '');
        localStorage.setItem('version_app', '1.1');
    }


    return (
        <ApolloProvider client={testClient}>
        <Providers>
            <Router>
                <Suspense fallback={<Loader/>}>
                    <Switch>
                        <Route exact path="/">
                            <DashboardPage/>
                        </Route>
                        {/* <Route exact path="/otc">
                            <OtcPage/>
                        </Route> */}
                        <Route exact path="/bridge">
                            <BridgePage/>
                        </Route>
                        <Route path="*">
                            <NoMatch/>
                        </Route>
                    </Switch>
                </Suspense>
            </Router>
        </Providers>
        </ApolloProvider>
    );
};

const Providers: React.FC = ({children}) => {
    return (
        <TP1 theme={theme}>
            <TP theme={newTheme}>
                    <Provider store={store}>
                        <Web3ProviderContext>
                        <Updaters/>
                        <RefreshContextProvider>
                         <BasedFinanceProvider>
                            <BridgeProvider>
                                <ModalsProvider>
                                    <OtcProvider>
                                    <BanksProvider>
                                        <>
                                            <Popups/>
                                            {children}
                                        </>
                                    </BanksProvider>
                                    </OtcProvider>
                                </ModalsProvider>
                                </BridgeProvider>
                                </BasedFinanceProvider>
                          </RefreshContextProvider>
                        </Web3ProviderContext>
                    </Provider>                
            </TP>
        </TP1>
    );
};

export default App;
